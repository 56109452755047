<template>
  <v-card>
    <h2 class="pl-12 primary--text">
      Tramitação Pedido
    </h2>
    <v-container>
      <v-timeline
        align-top
        dense
      >
        <v-timeline-item
          v-for="(item,i) in listaTramitacao"
          :key="i"
          small
        >
          <v-card
            width="300"
            class="ml-2"
          >
            <v-container>
              <v-row>
                <v-col
                  cols="12"
                  md="10"
                >
                  <v-row>
                    <strong class="pl-2">{{ item.txTramitacao }}</strong>
                  </v-row>
                  <v-row>
                    <span class="pl-2">{{ item.dtTramitacaoFormatado }}</span>
                  </v-row>
                  <v-row v-if="item.cdRota !=0">
                    <span class="pl-2">Rota: {{ item.cdRota }}</span>
                  </v-row>
                  <v-row>
                    <span class="pl-2 text-sm-h6 primary--text">{{ item.cdUsuario }}</span>
                  </v-row>
                </v-col>
                <v-col
                  class="hidden-sm-and-down text-right"
                  md="2"
                >
                  <v-icon size="32">
                    mdi-calendar-text
                  </v-icon>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </v-card>
</template>
<script>
  import tramitacaoOrcRep from '../../components/services/tramitacaoOrcamentoRepository'
  export default {
    props: {
      pedido: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        search: '',
        listaTramitacao: [],
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'item',
          },
          { text: 'Unid.', value: 'cdUnidade' },
          { text: 'Vl. Ajus.', align: 'end', value: 'vlAjuste' },
          { text: 'Vl. Unit.', align: 'end', value: 'vlUnitario' },
        ],
      }
    },
    async created () {
      this.$store.commit('setSplashScreen', true)
      await this.getLista()
      setTimeout(() => {
        // this.$refs.txtcodigo.focus()
        this.$store.commit('setSplashScreen', false)
      }, 1500)
    },
    methods: {
      async getLista () {
        await tramitacaoOrcRep.get(this.pedido).then(response => {
          // listaUsuarios = response.data
          this.listaTramitacao = response.data
        }).catch(() => {})
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>
