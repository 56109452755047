<template>
  <v-card>
    <v-card-title>
      Lista de Produtos
      <v-spacer />
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="listaProdutos"
      :search="search"
    >
      <template v-slot:item.item="{ item }">
        {{ item.item }} - {{ item.nmItem }}
      </template>
      <template v-slot:item.vlAjuste="{ item }">
        {{ formatPrice(item.vlAjuste) }}
      </template>
      <template v-slot:item.vlUnitario="{ item }">
        {{ formatPrice(item.vlUnitario) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
  import orcamentoRep from '../../components/services/orcamentoRepository'

  export default {
    props: {
      pedido: {
        type: Number,
        required: true,
      },
    },
    data () {
      return {
        search: '',
        listaProdutos: [],
        headers: [
          {
            text: 'Nome',
            align: 'start',
            value: 'item',
          },
          { text: 'Unid.', value: 'cdUnidade' },
          { text: 'Vl. Ajus.', align: 'end', value: 'vlAjuste' },
          { text: 'Vl. Unit.', align: 'end', value: 'vlUnitario' },
        ],
      }
    },
    async created () {
      this.$store.commit('setSplashScreen', true)
      await this.getLista()
      setTimeout(() => {
        // this.$refs.txtcodigo.focus()
        this.$store.commit('setSplashScreen', false)
      }, 1500)
    },
    methods: {
      async getLista () {
        await orcamentoRep.ItensPedido(this.pedido).then(response => {
          // listaUsuarios = response.data
          this.listaProdutos = response.data.dados
        }).catch(() => {
        })
      },
      formatPrice (value) {
        var val = (value / 1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      },
    },
  }
</script>

<style>

</style>
